import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  LazyImage,
  MainHeading,
  SubHeading,
  TextStyled,
} from "../../Styled/Elements";
import {
  AboutBg,
  AboutImg,
  AboutImg1,
  AboutImg2,
  AboutImg3,
  AboutImgAr,
  ArrowRight,
  ExpertiseBg,
  Stripe,
} from "../../Styled/AllImages";
import { ContactButton } from "../../Styled/CustomElements";
import { ExpertiseWrapper } from "./Styled";
import { expertiseList, scrollImages } from "../../data/Data";
import { useNavigate } from "react-router-dom";

const Expertise = ({ data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate()
  const TESTIMONIALS_ASSET_URL = process.env.REACT_APP_TESTIMONIAL_ASSET_URL;

  const settings = {
    dots: true,
    infinite: false,
    // initialSlide: 2,
    slidesToShow: 2,
    swipeToSlide: true,
    autoplay: false,
    speed: 1000,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <ExpertiseWrapper
        className="container-fluid common-space expertiseFluid"
        id="expertise"
      >
        {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
        <Box className="baseLayer baseLayerExpertise">
          <img className="expertiseBg" src={ExpertiseBg} />
        </Box>
        <Container>
          <Box className="expertiseRow row">
            <Col md={12}>
              <SubHeading className="sub-heading">
                {t("WitnessImpact")}
              </SubHeading>
              <MainHeading className="main-heading">
                {t("BeforeExperties")}
              </MainHeading>
            </Col>
            <Col lg={12} className="sliderWrapper">
              {data && data.length > 0 && (
                <Slider {...settings} className="scroll-container">
                  {data.map((item, index) => (
                    <Box className="slide-item-wrapper" key={index}>
                      <Box className="slide-item">
                        <Box className="slide-text">
                          <TextStyled className="slideTitle">
                            {t("slideTitle1")}
                          </TextStyled>
                          <ContactButton
                            size="14px"
                            icon={ArrowRight}
                            label={t("Becomethem")}
                            onClick={() => navigate('/our-packages')}
                          />
                        </Box>
                        <img
                          src={`${TESTIMONIALS_ASSET_URL}/${
                            language === "en" ? item.en_image : item.ar_image
                          }`}
                          alt=""
                          className="afImg"
                        />
                      </Box>
                    </Box>
                  ))}
                </Slider>
              )}
            </Col>
          </Box>
        </Container>
      </ExpertiseWrapper>
    </>
  );
};

export default Expertise;
