import styled from "styled-components";

export const BannerWrapper = styled.section`
    .bannerContentWrapper{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 100vh;
        min-height: 700px;
        .banner-content{
            width: 970px;
            /* position: relative; */
            .bannerMan{
                position: absolute;
                z-index: -1;
                right: 0;
                left: 0;
                bottom: -40px;
                margin: auto;
                display: none;
            }
            .bannerManEn{
                display: block;
            }
        }
        .bannerHeading{
            font-family: var(--medium);
        }
        .caption-sub-heading{
            font-size: 40px;
        }
        .caption-snd-heading{
            margin-bottom: 40px;
        }
        .caption-heading-main, .caption-snd-heading{
            font-size: 65px;
        }
    }
    .text-styled{
        position: relative;
        right: 15px;
        top: 10px;
    }
    .down-btn{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
        width: 90px;
        bottom: -10px;
    }
    @media (max-width: 991px) {
        .bannerContentWrapper {
            height: auto;
            min-height: auto;
            padding-top: 120px;
            padding-bottom: 100px;
            .caption-sub-heading {
                font-size: 35px;
            }
            .caption-snd-heading {
                margin-bottom: 10px;
                font-size: 50px;
            }
            .caption-heading-main {
				font-size: 50px;
				line-height: 1.3;
			}
            .banner-content .bannerMan {
                width: 430px;
                bottom: -10px;
            }
            
        }
        .contactWrapper{
            button{
                gap: 8px;
                height: 46px;
                padding: 0px 10px;
                span{
                    font-size: 16px;
                }
            } 
        }
        
    }
    @media (max-width: 767px) {
        .bannerContentWrapper {
            .banner-content .bannerMan {
                width: 380px;
            }
            .caption-sub-heading {
                font-size: 30px;
            }
            .caption-snd-heading {
                font-size: 40px;
            }
            .caption-heading-main {
				font-size: 40px;
			}
            
            
        }
        
        
    }
    @media (max-width: 575px) {
        .bannerContentWrapper {
            padding-top: 110px;
            padding-bottom: 50px;
            .banner-content .bannerMan {
                width: 330px;
                left: auto;
            }
        }
    }
    @media (max-width: 380px) {
        .bannerContentWrapper {
            .banner-content .bannerMan {
                width: 300px;
                bottom: 0px;
                right: -32px;
            }
        }
    }

`