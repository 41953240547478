import React from "react";
import { FooterWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate, Link as RouterLink } from "react-router-dom";
import { footerNavs, NavDataConfig, socialIcon } from "../data/Data";
import {
  Box,
  HeadingStyled,
  MainHeading,
  SpanStyled,
  TextStyled,
} from "../Styled/Elements";
import { ButtonWithIcon } from "../Styled/CustomElements";
import { Email, Logo, Phone, PhoneFooter } from "../Styled/AllImages";
import { privateRoute } from "../../route/PrivateRoute";

const PagesFooter = ({packages = []}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const date_year = new Date();
  const FowlUrl = () => {
    const whatsappURL = "https://fowl.sa/home";
    window.open(whatsappURL, "_blank");
  };
  const toMainPage = (label, isLink, to) => {
    if (isLink) {
      navigate(to);
    } else navigate(`/?section=${label}`);
  };
  const FowlWeb = () => {
    window.open("https://websites.fowl.sa/", "_blank");
  };
  return (
    <FooterWrapper className="container-fluid footerWrapper" lang={currentLang}>
      <Container className="footerContain">
        <Row>
          <Col md={12} lg={4} xl={3} className="footerCol footerCol1">
            <Box className="footerLogo">
              <Logo />
            </Box>
            <TextStyled
              className="footerAboutTxt"
              color="var(--text)"
              size="14px"
              family="var(--book)"
            >
              {t("footerAbout")}
            </TextStyled>
          </Col>
          <Col
            xs={5}
            sm={5}
            md={3}
            lg={2}
            xl={3}
            className="footerCol footerCol2"
          >
            <MainHeading className="main-heading footer-heading">
              {t("Links")}
            </MainHeading>
            <Box className="footerLinks">
              <Box className="footerLinksCol">
                {footerNavs.map((item, key) => (
                  <Box key={key}>
                    <SpanStyled
                      onClick={() => toMainPage(item.label, item.link, item.to)}
                      className="footer-op footer-op-allRights"
                      size="14px"
                      color="var(--text)"
                      style={{cursor: 'pointer'}}
                    >
                      {t(item.label)}
                    </SpanStyled>
                    {/* {item.link ? (
                      <RouterLink to={item.to} activeClass="active">
                        {t(item.label)}
                      </RouterLink>
                    ) : (
                      <Link
                        to={item.to}
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500}
                        activeClass="active"
                      >
                        {t(item.label)}{" "}
                      </Link>
                    )} */}
                  </Box>
                ))}
              </Box>
            </Box>
          </Col>
          <Col xs={7} sm={7} md={5} lg={3} className="footerCol footerCol3">
            <MainHeading className="main-heading footer-heading">
              {t("OurPackages")}
            </MainHeading>
            <Box className="footerLinks">
              <Box className="footerLinksCol">
                {packages.length > 0 && packages.slice(0, 7).map((pkg, i) => (
                  <Box>
                    <RouterLink to={`/package-details/${pkg.id}?package=${t(pkg.name)}`}>
                      {t(pkg.name)}
                    </RouterLink>
                  </Box>
                ))}
                {/* <Box>
                  <RouterLink to="">{t("StarterFitnessPackages")}</RouterLink>
                </Box>
                <Box>
                  <RouterLink to="">
                    {t("IntermediateFitnessPackage")}
                  </RouterLink>
                </Box>
                <Box>
                  <RouterLink to="">{t("AdvancedFitnessPackage")}</RouterLink>
                </Box>
                <Box>
                  <RouterLink to="">{t("HolisticWellnessPackage")}</RouterLink>
                </Box> */}
              </Box>
            </Box>
          </Col>
          <Col md={4} lg={3} className="footerCol footerCol4">
            <MainHeading className="main-heading footer-heading">
              {t("Contact_Us")}
            </MainHeading>
            {/* <ButtonWithIcon
              className="d-flex"
              clName="cntct-btn"
              label="+966 505444408"
              type="tel:+966505444408"
              icon={PhoneFooter}
              center
              orange
            /> */}
            <ButtonWithIcon
              className="d-flex"
              clName="cntct-btn"
              label="info@ownyou-health.com"
              icon={Email}
              center
              orange
            />
            <Box className="contactSocial">
              {socialIcon.map((list, key) => (
                <Box key={key} className="contact-links">
                  <a href={list.link} target="_blank" rel="noopener noreferrer">
                    <div className="social-icon">{list.img}</div>
                  </a>
                </Box>
              ))}
            </Box>
          </Col>
        </Row>
      </Container>
      <Box className="footerCrWrapper">
        <Container className="footerContain2">
          <Row className="footer-row align-items-center justify-content-center">
            <Box className="text-center copyright">
              <SpanStyled
                className="footer-op footer-op-allRights"
                size="14px"
                color="var(--text)"
                transform="capitalize"
              >
                {t("AllRightReserved2")} &copy; {date_year.getFullYear()}{" "}
              </SpanStyled>
              <SpanStyled
                className="footer-op"
                size="14px"
                color="var(--text)"
                transform="capitalize"
              >
                <SpanStyled
                  className="footer-op footer-op-company"
                  size="14px"
                  family="var(--medium)"
                  color="var(--themeColor)"
                >
                  {" "}
                  {t("OwnYourHealth")}
                </SpanStyled>
              </SpanStyled>
              <HeadingStyled
                className="footer-op footer-op-develop mt-2"
                size="14px"
                color="var(--text)"
                transform="capitalize"
              >
                {t("Developed_by")}
                <SpanStyled
                  className="fowl footer-op"
                  color="var(--themeColor)"
                  size="14px"
                  family="var(--medium)"
                  onClick={FowlWeb}
                >
                  {" "}
                  {t("Fowl")}
                </SpanStyled>
              </HeadingStyled>
            </Box>

            {/* <Col lg={2} md={12} sm={12}>
							<Box className="d-flex align-items-center gap-3 footerSocial">
							<LazyImage src={ProductsImg} />
							</Box>
						</Col> */}
          </Row>
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default PagesFooter;
